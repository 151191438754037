import styled from 'styled-components'
import { StyledClose } from '../common/styled'

export const StyledBanner = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.color || '#2264ea'};
  color: white;
  position: fixed;
  z-index: 900;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 0 42px 0 16px;
  }

  button.top-banner-close {
    height: 18px;
    width: 18px;
    top: 50%;
  }
`

export const StyledText = styled.div`
  color: ${(props) => props.color || '#fff'};
  max-width: calc(100% - 125px);
  font-size: 15px;
  line-height: 1;
  letter-spacing: -0.492308px;
  font-family: Eina03-SemiBold, sans-serif;
  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 16px;
    max-width: 100%;
    text-align: center;
  }
`
export const StyledLink = styled.a`
  margin: 0 0.5rem;
  min-width: max-content;
  text-decoration: none;
  border: 1px solid;
  border-color: ${(props) => props.color || '#fff'};
  padding: 0.1rem 0.6rem 0.2rem;
  border-radius: 8px;
  color: ${(props) => props.color || '#fff'};
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.430769px;
  font-family: Eina03-SemiBold, sans-serif;
  opacity: 0.7;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
  :hover {
    opacity: 0.9;
  }
`

export const BannerCloseIcon = styled(StyledClose)`
  right: 30px;
  top: 10px;
  color: ${(props) => props.color || '#fff'};
  @media screen and (max-width: 768px) {
    right: 5px;
  }
  :hover {
    cursor: pointer;
  }
  ::before,
  ::after {
    height: 14px;
    left: 0;
  }
`
